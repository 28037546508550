import { useCallback, useMemo } from "react";

import { ID, Ref } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { SystemPackages, useAnyHasPackages } from "@state/packages";
import { toDisplayName, useLazyGetPerson, useMe } from "@state/persons";
import { useAddToRecents } from "@state/recents";
import { useActiveSpace } from "@state/spaces";
import { useLazyTeamsFor } from "@state/teams";
import { toTemplateViewId } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { useStickyState } from "@utils/hooks";
import { isWorkspaceId } from "@utils/id";
import { maybeMap } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";

import { Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon } from "@ui/icon";
import { Main, PageLayout, TopBar } from "@ui/page-layout";
import { PersonStatusIcon } from "@ui/person-status-icon";
import { Sheet, WrapContainer } from "@ui/sheet-layout";
import { Switch } from "@ui/switch";
import { Heading, TextSmall } from "@ui/text";
import { ViewCard } from "@ui/view-card";
import { ViewCardCompact } from "@ui/view-card-compact";

import AppPage from "./app-page";

interface Props {
  personId: ID;
}

const PersonPage = ({ personId }: Props) => {
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const me = useMe();
  const space = useActiveSpace();
  const person = useLazyGetPerson(personId);
  const workspaceId = useActiveWorkspaceId();
  const [showAll, setShowAll] = useStickyState<boolean>(
    false,
    `show-all-${personId}`
  );
  const [page] = useRegisterPage("person", person);

  const teams = useLazyTeamsFor(personId);
  const toCheck = useMemo(
    () => [workspaceId, ...maybeMap(teams, (t) => t.id)],
    [workspaceId, teams]
  );

  const installed = useAnyHasPackages(toCheck, [
    SystemPackages.Meetings,
    SystemPackages.Outcomes,
    SystemPackages.Content,
    SystemPackages.Campaigns,
  ]);
  const viewParams = useMemo(
    () =>
      showAll
        ? { parent: me.id, for: workspaceId, owner: personId }
        : { parent: me.id, for: space.id, owner: personId },
    [personId, showAll, space.id]
  );
  const toId = useCallback(
    (template: string) => toTemplateViewId(template, viewParams),
    [viewParams]
  );

  const onOpenView = useCallback(
    (view: Ref) => person && goTo([person, view]),
    [goTo, person]
  );

  useAddToRecents(personId);

  usePageUndoRedo(page.id);

  if (!person) {
    return <></>;
  }

  return (
    <AppPage page={page} title="Person">
      <PageLayout bar="top">
        <TopBar>
          <Container>
            <SpaceBetween>
              <VStack gap={20}>
                <HStack>
                  {!!person && (
                    <Icon
                      icon={<PersonStatusIcon person={person} />}
                      size="xlarge"
                    />
                  )}
                  <VStack gap={0}>
                    <Heading bold>{person?.name || "Unkonwn"}</Heading>
                    <TextSmall>@{toDisplayName(person)}</TextSmall>
                  </VStack>
                </HStack>
                {!isWorkspaceId(space.id) && (
                  <Switch
                    size="small"
                    checked={showAll}
                    onChange={setShowAll}
                    label="Show work from all teams"
                    subtle
                  />
                )}
              </VStack>
            </SpaceBetween>
          </Container>
        </TopBar>

        <Main>
          <WrapContainer>
            <SpaceBetween fit="container" align="stretch" gap={16}>
              <Sheet size="primary" mode="sizing" height="content">
                <VStack fit="container">
                  <ViewCard
                    size="full"
                    viewId={toId("my-in-progress")}
                    onOpen={onOpenView}
                  />

                  <ViewCard
                    size="full"
                    viewId={toId("my-ready")}
                    onOpen={onOpenView}
                  />

                  <ViewCardCompact
                    size="full"
                    viewId={toId("my-planned")}
                    onOpen={onOpenView}
                    limit={10}
                  />
                  <ViewCardCompact
                    size="full"
                    viewId={toId("my-recently-completed")}
                    onOpen={onOpenView}
                    limit={10}
                  />
                </VStack>
              </Sheet>
              <Sheet size="secondary" mode="sizing" height="content">
                <VStack fit="container">
                  {!!installed[SystemPackages.Meetings] && (
                    <>
                      <ViewCardCompact
                        viewId={toId("all-meetings")}
                        onOpen={pushTo}
                        empty="collapse"
                        size="full"
                      />
                      <ViewCardCompact
                        viewId={toId("my-actions")}
                        onOpen={pushTo}
                        empty="collapse"
                        size="full"
                      />
                    </>
                  )}

                  {!!installed[SystemPackages.Outcomes] && (
                    <ViewCardCompact
                      viewId={toId("my-outcomes")}
                      empty="collapse"
                      onOpen={pushTo}
                      limit={5}
                      size="full"
                    />
                  )}

                  {!!installed[SystemPackages.Campaigns] && (
                    <ViewCardCompact
                      viewId={toId("my-campaigns")}
                      empty="collapse"
                      onOpen={pushTo}
                      limit={5}
                      size="full"
                    />
                  )}

                  {!!installed[SystemPackages.Content] && (
                    <ViewCardCompact
                      viewId={toId("my-contents")}
                      empty="collapse"
                      onOpen={pushTo}
                      limit={5}
                      size="full"
                    />
                  )}
                </VStack>
              </Sheet>
            </SpaceBetween>
          </WrapContainer>
        </Main>
      </PageLayout>
    </AppPage>
  );
};

export default PersonPage;
