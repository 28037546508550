import { Ref } from "@api";

import { useRecentsForScope } from "@state/recents";

import { maybeMap } from "@utils/array";
import { withHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { useShowMore } from "@utils/hooks";

import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { EmptyMenuItem, MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { RelationLabel, RelationText } from "@ui/relation-label";

export const RecentsMenuList = ({
  scope,
  onOpen,
}: {
  scope: string;
  onOpen?: Fn<Ref, void>;
}) => {
  const recents = useRecentsForScope(scope);
  const { visible, hasMore, moreCount } = useShowMore(recents, 5);

  return (
    <Menu>
      <MenuGroup>
        {maybeMap(
          visible,
          (r) =>
            r.id !== scope && (
              <MenuItem
                key={r.id}
                onClick={!!onOpen ? withHandle(() => onOpen?.(r)) : undefined}
              >
                <RelationLabel relation={r} />
              </MenuItem>
            )
        )}
        {!visible?.length && (
          <EmptyMenuItem indent={0}>Nothing viewed recently</EmptyMenuItem>
        )}

        {hasMore && <ShowMoreMenuItem text="Show all" count={moreCount} />}
      </MenuGroup>
    </Menu>
  );
};
