import { map } from "lodash";
import { useCallback } from "react";

import { Color, ID, Ref } from "@api";

import { useUpdateEntity } from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";
import { useMe } from "@state/persons";
import { ThemeMode, useSetting } from "@state/settings";
import { useActiveWorkspaceId, useSession } from "@state/workspace";

import { cx } from "@utils/class-names";
import { Maybe, when } from "@utils/maybe";
import { asMutation, toMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";

import { Button } from "@ui/button";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Check, Icon, LineAlt, PersonIcon, Swatch } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { Label } from "@ui/label";
import { showWarning } from "@ui/notifications";
import { OnHover } from "@ui/on-hover";
import { ColorSelect } from "@ui/select/color";
import { SettingsSection, SettingsSectionGroup } from "@ui/settings-section";
import { Switch } from "@ui/switch";
import { Text, TextSmall } from "@ui/text";

import { GoogleAuthorize } from "./google-authorize";
import { NotionAuthorize } from "./notion-authorize";
import { SlackAuthorize } from "./slack-authorize";
import { TeamSelect } from "./team-select";
import { UploadFileButton } from "./upload-modal";

import styles from "./settings-personal.module.css";

const SUGGESTED: Maybe<Color>[] = [
  undefined,
  "gray_2",
  "pink_4",
  "green_2",
  "blue_5",
  "yellow_4",
  "orange_4",
  "purple_5",
  "turquoise_5",
];

export function SettingsPersonalConnections() {
  const workspace = useActiveWorkspaceId();
  const session = useSession();
  const installed = useHasPackages(workspace, [
    SystemPackages.Notion,
    SystemPackages.Slack,
    SystemPackages.Google,
  ]);

  return (
    <SettingsSectionGroup title="Connected Accounts">
      {installed[SystemPackages.Notion] !== false && (
        <SettingsSection
          title="Notion Account"
          subtitle="Link your notion account to quickly create and search pages."
        >
          {!!session?.auths?.notion?.user && (
            <Label fit="content" icon={Check}>
              Linked
            </Label>
          )}
          <NotionAuthorize />
        </SettingsSection>
      )}

      {installed[SystemPackages.Slack] !== false && (
        <SettingsSection
          title="Slack Account"
          subtitle="Link your slack account to link and reply to messages."
        >
          {!!session?.auths?.slack?.user && (
            <Label fit="content" icon={Check}>
              Linked
            </Label>
          )}
          <SlackAuthorize />
        </SettingsSection>
      )}

      {installed[SystemPackages.Google] !== false && (
        <SettingsSection
          title="Google Account"
          subtitle="Link your google account to access google drive and calendar."
        >
          {!!session?.auths?.google?.user?.token && (
            <Label fit="content" icon={Check}>
              Linked
            </Label>
          )}

          <GoogleAuthorize />
        </SettingsSection>
      )}
    </SettingsSectionGroup>
  );
}

export function SettingsPersonalThemes() {
  const me = useMe();
  const mutate = useUpdateEntity(me.id);

  const themeMode = useSetting<ThemeMode>(me.id, "settings.appThemeMode");
  const setThemeMode = useCallback(
    (mode: Maybe<ThemeMode>) =>
      mutate(
        asMutation({ field: "settings.appThemeMode", type: "text" }, mode)
      ),
    [mutate]
  );

  const themeColor = useSetting<Color>(me.id, "settings.appColor");
  const setThemeColor = useCallback(
    (color: Maybe<Color>) =>
      mutate(asMutation({ field: "settings.appColor", type: "text" }, color)),
    [mutate]
  );

  const useTeamTint = useSetting<boolean>(me.id, "settings.useTeamColor");
  const setUseTeamTint = useCallback(
    (use: Maybe<boolean>) =>
      mutate(
        asMutation({ field: "settings.useTeamColor", type: "boolean" }, use)
      ),
    [mutate]
  );

  return (
    <SettingsSectionGroup title="Themes">
      <SettingsSection
        title="Dark/Light Mode"
        subtitle="Choose your side.... wisely."
      >
        <ButtonGroup>
          <SplitButton
            selected={themeMode === "light"}
            onClick={() => setThemeMode("light")}
          >
            Light
          </SplitButton>
          <SplitButton
            selected={themeMode === "dark"}
            onClick={() => setThemeMode("dark")}
          >
            Dark
          </SplitButton>
          <SplitButton
            selected={!themeMode}
            onClick={() => setThemeMode(undefined)}
          >
            System
          </SplitButton>
        </ButtonGroup>
      </SettingsSection>

      <SettingsSection
        title="Tint"
        subtitle="Choose the tint color of your theme."
      >
        <HStack>
          {map(SUGGESTED, (color) => (
            <Icon
              key={color}
              className={cx(themeColor === color && styles.selected)}
              size="large"
              icon={color ? <Swatch color={color} /> : LineAlt}
              onClick={() => setThemeColor(color)}
            />
          ))}
          <Divider direction="vertical" />
          <ColorSelect
            color={themeColor}
            onChange={(c) => c && setThemeColor(c)}
          >
            <Icon
              size="large"
              icon={themeColor ? <Swatch color={themeColor} /> : LineAlt}
            />
          </ColorSelect>
        </HStack>
      </SettingsSection>

      <SettingsSection
        title="Team Tints"
        subtitle="Override chosen tint with team color when entering a team space."
      >
        <Switch checked={useTeamTint} onChange={setUseTeamTint} />
      </SettingsSection>
    </SettingsSectionGroup>
  );
}

export function SettingsPersonalDetails() {
  const me = useMe();
  const mutate = useUpdateEntity(me.id);

  const defaultTeam = useSetting<ID>(me.id, "settings.defaultLocation");
  const setDefaultTeam = useCallback(
    (team: Maybe<Ref>) =>
      mutate(
        asMutation(
          { field: "settings.defaultLocation", type: "relation" },
          team
        )
      ),
    [mutate]
  );

  return (
    <>
      <SettingsSectionGroup title="Personal Details">
        <Container padding="none" inset="horizontal">
          <SpaceBetween gap={10}>
            <OnHover.Trigger>
              <Field padded={false}>
                <HStack align="flex-end">
                  <UploadFileButton
                    icon={false}
                    scope={me.id}
                    onUploaded={(file) =>
                      mutate(
                        asMutation({ field: "avatar", type: "text" }, file.url)
                      )
                    }
                  >
                    <Icon
                      size="xxlarge"
                      icon={<PersonIcon size="xxlarge" person={me} />}
                    />
                  </UploadFileButton>
                  <OnHover.Target>
                    {!!me?.avatar && (
                      <Button
                        size="small"
                        subtle
                        onClick={() =>
                          mutate(
                            asMutation(
                              { field: "avatar", type: "text" },
                              undefined
                            )
                          )
                        }
                      >
                        <Text subtle>Clear</Text>
                      </Button>
                    )}
                  </OnHover.Target>
                </HStack>
              </Field>
            </OnHover.Trigger>
          </SpaceBetween>
        </Container>
        <Container padding="none" inset="horizontal">
          <SpaceBetween gap={10}>
            <Field label="Name" padded={false}>
              <HStack gap={0}>
                <TextInput
                  value={me?.name || ""}
                  onChange={(name) =>
                    name &&
                    mutate([
                      toMutation(me, { field: "name", type: "text" }, name),
                    ])
                  }
                  updateOn="blur"
                  placeholder="Name..."
                />
              </HStack>
            </Field>

            <Field
              label="Email"
              onClick={() => showWarning("Contact support to change email.")}
            >
              <TextInput
                disabled={true}
                value={me?.email || ""}
                updateOn="blur"
                placeholder="Email..."
              />
            </Field>
          </SpaceBetween>
        </Container>
        <TextSmall subtle>
          The above details are used across all Workspaces.
        </TextSmall>

        <Divider />
      </SettingsSectionGroup>

      <SettingsSectionGroup title="Defaults">
        <SettingsSection
          title="Location for new work"
          subtitle="Fallback location to save work that doesn't belong to a team."
        >
          <TeamSelect
            team={when(defaultTeam, toRef)}
            portal={true}
            onChanged={setDefaultTeam}
          />
        </SettingsSection>
      </SettingsSectionGroup>
    </>
  );
}

export function SettingsPersonal() {
  return (
    <VStack gap={30}>
      <SettingsPersonalDetails />
      <SettingsPersonalThemes />
      <SettingsPersonalConnections />
    </VStack>
  );
}
