import { map } from "lodash";
import { useMemo } from "react";

import { DatabaseID, SelectOption } from "@api";

import { useLazyPropertyValues } from "@state/properties";
import { useStore } from "@state/generic";
import { getItem } from "@state/store";

import { asLocal, cid } from "@utils/id";
import { maybeMap } from "@utils/maybe";

import { useMultiCheckedState } from "@ui/checked";
import { CommandItem } from "@ui/command-menu";
import { CheckIcon } from "@ui/icon";
import { StatusTag, Tag } from "@ui/tag";
import { Text } from "@ui/text";

import { useCommandSearch } from "../utils";
import { SetPropertyCommands } from "./types";

export const SelectCommands = ({
  property,
  entities,
  mutate,
}: SetPropertyCommands) => {
  const propType = property.type;
  if (
    propType !== "select" &&
    propType !== "multi_select" &&
    propType !== "status"
  ) {
    throw new Error(
      'SelectCommands can only be used on "select" or "multi_select" properties.'
    );
  }
  const source = entities[0]?.source as DatabaseID;
  const values = useLazyPropertyValues(source, property);
  const store = useStore(source.type);
  const realEntities = maybeMap(entities, (e) => getItem(store, e.id));
  const toCheckedState = useMultiCheckedState(realEntities, property);
  const search = useCommandSearch();

  const options = useMemo(
    () =>
      map(values[propType], (s: SelectOption) => ({
        option: s,
        checked: toCheckedState(s.id || s.name || ""),
      })),
    [values, toCheckedState]
  );

  return (
    <>
      {propType !== "status" && (
        <CommandItem
          value="clear unset value"
          onClick={() => mutate({ [propType]: undefined })}
        >
          <Text subtle>Clear value</Text>
        </CommandItem>
      )}
      {map(options, ({ option, checked }) => {
        return (
          <CommandItem
            key={option.id || option.name}
            value={`${option.name} ${option.id}`}
            onClick={() => mutate({ [propType]: option })}
            icon={
              entities?.length > 1 ? <CheckIcon checked={checked} /> : undefined
            }
          >
            {propType !== "status" && (
              <Tag color={option.color}>{option.name}</Tag>
            )}
            {propType === "status" && <StatusTag status={option} />}
          </CommandItem>
        );
      })}
      {!property.locked && search && (
        <CommandItem
          value={`skip: create ${search}`}
          onClick={() =>
            mutate({ [propType]: { id: asLocal(cid(4)), name: search } })
          }
        >
          <Text subtle>Create</Text> <Tag>{search}</Tag>
        </CommandItem>
      )}
    </>
  );
};
