import { ReactNode } from "react";

import { Centered } from "@ui/container";
import { Divider } from "@ui/divider";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Heading, Text, TextLarge } from "@ui/text";

import styles from "./settings-section.module.css";

interface Props {
  title: string;
  subtitle?: string;
  divider?: boolean;
  children: ReactNode;
}

export const SettingsSectionGroup = ({
  children,
  title,
  subtitle,
  divider = false,
}: Props) => {
  return (
    <Centered gap={20} stack="vertical" className={styles.sectionGroup}>
      <VStack gap={0}>
        <Heading bold>{title}</Heading>
        {!!subtitle && <Text subtle>{subtitle}</Text>}
      </VStack>

      {divider ? <Divider direction="horizontal" /> : false}

      <VStack gap={20} fit="container">
        {children}
      </VStack>
    </Centered>
  );
};

export const SettingsSection = ({
  title,
  subtitle,
  children,
  divider,
}: Props) => {
  return (
    <>
      <SpaceBetween fit="container">
        <FillSpace>
          <VStack gap={0}>
            <TextLarge bold>{title}</TextLarge>
            {!!subtitle && <Text subtle>{subtitle}</Text>}
          </VStack>
        </FillSpace>

        <HStack>{children}</HStack>
      </SpaceBetween>

      {!divider && <Divider direction="horizontal" />}
    </>
  );
};
