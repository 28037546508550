import { map } from "lodash";

import { Form, Ref } from "@api";

import { useLazyFetchResults } from "@state/fetch-results";

import { usePushTo } from "@utils/navigation";
import { toRef } from "@utils/property-refs";

import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Sheet } from "@ui/sheet-layout";

import { render, useEngine } from "..";

type Props = {
  form: Form;
  submitter?: Ref;
};

export const FormSubmissionsPane = ({ form, submitter }: Props) => {
  const pushTo = usePushTo();
  const forms = useLazyFetchResults(`form-submissions-${form.id}`, "form", {
    and: [
      {
        field: "refs.fromTemplate",
        type: "relation",
        op: "contains",
        value: { relation: toRef(form) },
      },
      {
        field: "createdAt",
        type: "date",
        op: "greater_than",
        value: { formula: "=today-30d" },
      },
    ],
  });
  const engine = useEngine("form");

  return (
    <Sheet size="secondary">
      <PaneHeader title={"Recent Submissions"} />
      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(forms, (form) =>
              render(engine.asMenuItem, {
                key: form.id,
                item: form,
                onOpen: pushTo,
              })
            )}
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};
