import { map } from "lodash";
import { useCallback, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { useRegisterPage } from "@state/app";
import { useLogout } from "@state/generic";
import { hasAdminAccess, useMe } from "@state/persons";
import { toLabel, useLazyAllTeams } from "@state/teams";
import { useCurrentWorkspace } from "@state/workspace";

import { withHandle, withHardHandle } from "@utils/event";
import { useShowMore } from "@utils/hooks";
import { when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { IgnoreSelectable } from "@utils/selectable";

import { AppHeader } from "@ui/app-header";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { SpaceBetween } from "@ui/flex";
import {
  ArrowUpRight,
  Box,
  Brush,
  Cog,
  CompanyFilled,
  Exit,
  EyeSlash,
  Icon,
  ImageIcon,
  InfoCircle,
  LabelAlt,
  PersonIcon,
  TeamIcon,
  TimesIcon,
  Traction,
  TractionHorizontal,
  UsersAlt,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { ExpandableMenuItem } from "@ui/menu-item/expandable";
import { Modal } from "@ui/modal";
import { OnHover } from "@ui/on-hover";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { Redirect } from "@ui/redirect";
import { SettingsWorkspacePackages } from "@ui/settings-packages";
import {
  SettingsPersonal,
  SettingsPersonalConnections,
  SettingsPersonalThemes,
} from "@ui/settings-personal";
import SettingsTeam from "@ui/settings-team";
import {
  SettingsWorkspace,
  SettingsWorkspaceData,
  SettingsWorkspacePeople,
  SettingsWorkspaceProperties,
} from "@ui/settings-workspace";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import WithParams from "@ui/with-params";

import AppPage from "./app-page";

import styles from "./settings-page.module.css";

interface Props {}

function SettingsCore(props: Props) {
  const location = useLocation();
  const me = useMe();
  const workspace = useCurrentWorkspace();
  const goTo = useGoTo();
  const logout = useLogout();
  const allTeams = useLazyAllTeams();
  const isAdmin = useMemo(() => hasAdminAccess(me), [me?.role?.id]);
  const { hasMore, visible, showMore } = useShowMore(allTeams, 5);

  const goToSettingsPage = (group: string) => goTo(`/settings${group}`);

  return (
    <IgnoreSelectable>
      <PageLayout>
        <SideNav className={styles.nav}>
          <SpaceBetween direction="vertical" gap={20}>
            <Container padding="none" fit="container">
              <TractionHorizontal />
            </Container>

            <Divider />

            <Menu className={styles.navMenu}>
              <MenuGroup>
                <ExpandableMenuItem
                  icon={<Icon icon={<PersonIcon person={me} />} />}
                  defaultExpanded={true}
                  text="Personal"
                  saveKey="settings-personal"
                  selected={location.pathname.includes("/personal")}
                  onClick={() => goToSettingsPage("/personal")}
                >
                  <MenuItem
                    indent={1}
                    text="Themes"
                    icon={Brush}
                    onClick={() => goToSettingsPage("/personal/themes")}
                  />
                  <MenuItem
                    indent={1}
                    text="Connections"
                    icon={UsersAlt}
                    onClick={() => goToSettingsPage("/personal/connections")}
                  />
                </ExpandableMenuItem>
              </MenuGroup>

              <MenuGroup>
                <ExpandableMenuItem
                  defaultExpanded={false}
                  icon={<Icon icon={CompanyFilled} />}
                  text="Teams"
                  saveKey="settings-teams"
                >
                  {map(visible, (team) => (
                    <OnHover.Trigger key={team.id}>
                      <MenuItem
                        icon={<TeamIcon team={team} />}
                        indent={1}
                        text={toLabel(team)}
                        iconRight={
                          <OnHover.Target>
                            <Button
                              size="tiny"
                              icon={ArrowUpRight}
                              onClick={withHardHandle(() =>
                                goTo(`/${team.id}/settings/data`)
                              )}
                            />
                          </OnHover.Target>
                        }
                        onClick={() => goToSettingsPage(`/team/${team.id}`)}
                      />
                    </OnHover.Trigger>
                  ))}
                  {hasMore && <ShowMoreMenuItem onClick={showMore} />}
                </ExpandableMenuItem>
              </MenuGroup>

              {isAdmin && (
                <MenuGroup>
                  <ExpandableMenuItem
                    defaultExpanded={true}
                    icon={
                      <Icon
                        icon={
                          when(workspace?.icon, (url) => (
                            <ImageIcon url={url} />
                          )) || <Traction />
                        }
                      />
                    }
                    text="Workspace"
                    saveKey="settings-workspacel"
                    onClick={() => goToSettingsPage("/workspace")}
                  >
                    <MenuItem
                      indent={1}
                      text="Details"
                      icon={InfoCircle}
                      onClick={() => goToSettingsPage("/workspace/data")}
                    />
                    <MenuItem
                      indent={1}
                      text="Properties"
                      icon={LabelAlt}
                      onClick={() => goToSettingsPage("/workspace/fields")}
                    />
                    <MenuItem
                      indent={1}
                      text="Packages"
                      icon={Box}
                      onClick={() => goToSettingsPage("/workspace/packages")}
                    />
                    <MenuItem
                      indent={1}
                      text="People"
                      icon={UsersAlt}
                      onClick={() => goToSettingsPage("/workspace/people")}
                    />
                  </ExpandableMenuItem>
                </MenuGroup>
              )}
            </Menu>

            <Menu>
              <MenuGroup>
                <MenuItem text="Logout" icon={Exit} onClick={() => logout()} />
              </MenuGroup>
            </Menu>
          </SpaceBetween>
        </SideNav>

        <Main className={styles.main}>
          <Routes location={location}>
            <Route path="personal">
              <Route path="themes" element={<SettingsPersonalThemes />} />
              <Route
                path="connections"
                element={<SettingsPersonalConnections />}
              />
              <Route path="*" element={<Redirect to="/settings/personal" />} />
              <Route path="" element={<SettingsPersonal />} />
            </Route>

            <Route
              path="team/:teamId"
              element={
                <WithParams
                  params={({ teamId }) => ({
                    teamId: teamId || me.id,
                  })}
                  component={SettingsTeam}
                />
              }
            />

            <Route path="workspace">
              <Route path="data" element={<SettingsWorkspaceData />} />
              <Route path="fields" element={<SettingsWorkspaceProperties />} />
              <Route path="people" element={<SettingsWorkspacePeople />} />
              <Route path="packages" element={<SettingsWorkspacePackages />} />
              <Route path="*" element={<Redirect to="/settings/workspace" />} />
              <Route path="" element={<SettingsWorkspace />} />
            </Route>
          </Routes>
        </Main>
      </PageLayout>
    </IgnoreSelectable>
  );
}

export function SettingsPage(props: Props) {
  const [page] = useRegisterPage("settings");
  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size="full" transparency="mid" interactable={false}>
          <SettingsCore {...props} />
        </Sheet>
      </StackContainer>
    </AppPage>
  );
}

export function SettingsModal(props: Props) {
  const [page] = useRegisterPage("settings-modal");
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = useCallback(
    () => navigate(location.state.return),
    [location.state?.return?.pathname]
  );

  return (
    <AppPage page={page}>
      <Modal open={true} onOpenChanged={handleClose} className={styles.modal}>
        <AppHeader
          links={[]}
          right={
            <>
              <Button
                as="button"
                subtle
                size="small"
                icon={TimesIcon}
                onClick={handleClose}
              />
            </>
          }
        />
        <SettingsCore {...props} />
      </Modal>
    </AppPage>
  );
}
