import { initial, last } from "lodash";
import Head from "next/head";
import { useEffect, useState } from "react";
import { Location, Route, Routes, useLocation } from "react-router-dom";

import { useThemeColor, useThemeMode } from "@state/settings";
import { useEnsureAuthed } from "@state/workspace";

import { defs } from "@utils/maybe";

import { AppCommands } from "@ui/app-commands";
import { ArchiveBanner } from "@ui/archive-banner";
import { ErrorBoundary } from "@ui/error-boundary";
import { GlassBackground } from "@ui/glass-background";
import { JobsQueue } from "@ui/jobs-queue";
import ComponentLibraryPage from "@ui/page/component-library";
import { SettingsModal, SettingsPage } from "@ui/page/settings-page";
import * as Layout from "@ui/page-layout";
import { PresenceSync } from "@ui/presence-sync";
import PrimaryNav from "@ui/primary-nav";
import { SavingQueue } from "@ui/saving-queue";
import { WithTheme } from "@ui/theme";
import WithParams, { WithLocationParts } from "@ui/with-params";
import { WorkflowSuggestions } from "@ui/workflow-suggestions";

import { AppRoute } from "./app-route";
import { MyWorkPage } from "./my-work-page";
import { TeamsPage } from "./teams-page";

const isModal = (l: Location) =>
  (l.state as { modal: boolean })?.modal === true;

export default function AuthedRoutes() {
  useEnsureAuthed();
  const color = useThemeColor();
  const mode = useThemeMode();

  const [prev, setPrev] = useState<Location>();
  const location = useLocation();

  useEffect(() => {
    if (!isModal(location)) {
      setPrev(location);
    }
  }, [location]);

  return (
    <WithTheme color={color} mode={mode || "light"}>
      <Layout.AppRoot>
        <GlassBackground>
          <Head>
            <meta name="description" content="" />
            <meta
              name="viewport"
              content="width=device-width, height=device-height, initial-scale=1, user-scalable=no"
            />
          </Head>

          <AppCommands />

          <ErrorBoundary>
            <JobsQueue />
            <SavingQueue />
          </ErrorBoundary>

          <WorkflowSuggestions />

          <PresenceSync />

          <ArchiveBanner />

          <Layout.AppLayout>
            <Layout.SideNav>
              <PrimaryNav />
            </Layout.SideNav>

            <Layout.Main>
              <ErrorBoundary>
                <Routes location={isModal(location) && prev ? prev : location}>
                  <Route path="components" element={<ComponentLibraryPage />} />
                  <Route path="teams" element={<TeamsPage />} />
                  <Route path="settings/*" element={<SettingsPage />} />
                  <Route
                    path="boards"
                    element={<MyWorkPage viewId={undefined} />}
                  />
                  <Route
                    path="boards/:id"
                    element={
                      <WithParams
                        params={({ id }) => ({ viewId: id })}
                        component={MyWorkPage}
                      />
                    }
                  />

                  <Route
                    path="*"
                    element={
                      <WithLocationParts
                        params={(parts) => ({
                          part: defs(last(parts)),
                          parents: initial(parts) || [],
                        })}
                        component={AppRoute}
                      />
                    }
                  />
                </Routes>
              </ErrorBoundary>
            </Layout.Main>

            {/* Modal Routes */}
            {isModal(location) && (
              <Routes location={location}>
                <Route path="settings">
                  <Route
                    path=":setting"
                    element={
                      <WithParams
                        params={({ setting }) => ({
                          group: setting,
                        })}
                        component={SettingsModal}
                      />
                    }
                  />
                  <Route
                    path=""
                    element={
                      <WithParams
                        params={({}) => ({})}
                        component={SettingsModal}
                      />
                    }
                  />
                </Route>
              </Routes>
            )}
          </Layout.AppLayout>
        </GlassBackground>
      </Layout.AppRoot>
    </WithTheme>
  );
}
