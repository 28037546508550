import { toTemplateViewId } from "@state/views";

import { useStickyState } from "@utils/hooks";
import { ID, Maybe } from "@utils/maybe";

import { Main, PageLayout } from "@ui/page-layout";
import { Sheet } from "@ui/sheet-layout";
import SmartViewPane from "@ui/view-pane";

// Not an actual "Page" used as a sub-page within TeamPage
export const TeamMeetingsPage = ({ teamId }: { teamId: ID }) => {
  const [viewId, setViewId] = useStickyState<Maybe<string>>(
    () =>
      toTemplateViewId(`all-meetings`, {
        parent: teamId,
        entity: "meeting",
      }),
    `${teamId}-meeting-view-id`
  );

  return (
    <Sheet size="full" transparency="mid" interactable={false}>
      <PageLayout>
        <Main>
          {viewId && (
            <SmartViewPane
              viewId={viewId}
              onChangeView={(v) => setViewId(v.id)}
              showTitle={false}
              showViewsBar={false}
              // showHeader={false}
            />
          )}
        </Main>
      </PageLayout>
    </Sheet>
  );
};
