import { filter, uniq, values } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { EntityType, ID } from "@api";

import {
  allPropertiesForTeam,
  allRelationsForTeam,
  toHierarchyPairs,
} from "@state/properties";
import { useInstalledEntities, useRelationCreateRemove } from "@state/packages";
import { useEntityLabels } from "@state/settings";

import {
  ensureMany,
  groupByMany,
  justOne,
  maybeMap,
  overlaps,
} from "@utils/array";
import { toFlowDefinition, treeLayout, useFlowData } from "@utils/chart";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { mapAll } from "@utils/promise";
import { toFieldName } from "@utils/property-refs";

import { Connection, Edge, Node } from "@ui/flow";
import { Flow, withFlowProvider } from "@ui/flow";

import styles from "./team-schema-flow.module.css";

interface Props {
  teamId: ID;
  onSelected?: Fn<EntityType, void>;
  interactable?: boolean;
  className?: string;
}

const useGraphableInstalledEntities = (teamId: ID) => {
  const installed = useInstalledEntities(teamId);
  return useMemo(
    () =>
      filter(
        installed,
        (e) =>
          !equalsAny(e, [
            "meeting",
            "process",
            "form",
            "workflow",
            "note",
            "page",
            "team",
            "workspace",
            "resource",
          ])
      ),
    [installed]
  );
};

export const TeamSchemaFlow = withFlowProvider(
  ({ teamId, onSelected, className, interactable = true }: Props) => {
    const { deleteConnection, createConnection } =
      useRelationCreateRemove(teamId);
    const toEntityLabel = useEntityLabels(teamId, { plural: true });
    const installed = useGraphableInstalledEntities(teamId);
    const allProps = useRecoilValue(allPropertiesForTeam(teamId));
    const props = useRecoilValue(allRelationsForTeam(teamId));
    const pairs = useMemo(() => toHierarchyPairs(props), [props]);

    const toTypeConnections = useMemo(() => {
      const allPairs = values(pairs);
      const grouped = groupByMany(allPairs, ([def]) => ensureMany(def.entity));
      return (type: EntityType) => grouped[type] || [];
    }, [pairs]);

    const [selected, setSelected] = useState<string[]>([]);
    const customCounts = useMemo(
      () =>
        groupByMany(
          filter(allProps, (p) => !p.system),
          (prop) => prop.entity
        ),
      [installed]
    );

    const strat = useMemo(
      () =>
        treeLayout(
          { width: 120, height: 80, hSpace: 1, vSpace: 5 },
          { orientation: "vertical" }
        ),
      []
    );
    const flowDefinition = useMemo(
      () =>
        toFlowDefinition<
          EntityType,
          { label: string; subTitle: string; entity: EntityType },
          { selected: boolean; label: string }
        >({
          layout: strat,
          fitView: { maxZoom: 1, minZoom: 0.5, padding: 0.05, duration: 100 },
          toNode: (type) => ({
            id: type,
            draggable: interactable,
            data: {
              label: toEntityLabel(type),
              subTitle: `${customCounts[type]?.length || "No"} custom fields`,
              entity: type as EntityType,
            },
            type: "entity-type",
          }),
          toEdges: (type) =>
            maybeMap(toTypeConnections(type) || [], ([parent, child]) => {
              // Don't graph self-references
              if (!parent || parent.entity[0] === child.entity[0]) {
                return undefined;
              }

              return {
                id: `${parent.entity[0]}-${child.entity[0]}`,
                source: parent.entity[0],
                target: justOne(parent.options?.references) || "",
                type: "step",
                data: {
                  selected: overlaps(selected, [
                    parent.entity[0],
                    justOne(parent.options?.references),
                  ]),
                  label: parent.label || toFieldName(parent) || "",
                },
              };
            }),
        }),
      [installed, props, selected]
    );

    const { nodes, edges, toPosition, ...flowProps } = useFlowData(
      installed,
      flowDefinition
    );

    const handleConnect = useCallback(async (edge: Edge | Connection) => {
      await createConnection(
        edge.source as EntityType,
        edge.target as EntityType,
        "child"
      );
    }, []);

    const handleDelete = useCallback(
      async (connection: Edge[]) => {
        await mapAll(connection, async (edge) => {
          const pair = pairs[edge.id];
          if (pair) {
            await deleteConnection(pair);
          }
        });
      },
      [pairs]
    );

    const onNodeClicked = useCallback((event: React.MouseEvent, node: Node) => {
      if (event?.defaultPrevented) {
        return;
      }

      onSelected?.(node?.data.entity);
      setSelected((s) =>
        s?.includes(node?.data.entity) ? [] : uniq([node.id])
      );
    }, []);

    return (
      <Flow
        nodes={nodes}
        edges={edges}
        showControls={interactable}
        preventScrolling={!interactable}
        panOnDrag={!interactable}
        className={cx(className, !interactable && styles.readonly)}
        {...flowProps}
        onConnect={handleConnect}
        onEdgesDelete={handleDelete}
        onNodeClick={onNodeClicked}
      />
    );
  }
);
