import { map } from "lodash";
import { useCallback } from "react";

import { DatabaseID, Entity, PropertyType } from "@api";

import { useStore } from "@state/generic";
import { getItem } from "@state/store";

import { Maybe, maybeMap } from "@utils/maybe";
import { toUpdate } from "@utils/property-mutations";
import { getPropertyValue } from "@utils/property-refs";

import { useMultiCheckedState } from "@ui/checked";
import { CommandItem } from "@ui/command-menu";
import { CheckIcon } from "@ui/icon";
import { Text } from "@ui/text";

import { SetPropertyCommands } from "./types";

export const BooleanCommands = ({
  property,
  entities,
  mutate,
}: SetPropertyCommands) => {
  const propType = property.type;

  if (propType !== "boolean") {
    throw new Error("Can only use BooleanCommands on boolean property types.");
  }

  const source = entities[0]?.source as DatabaseID;
  const store = useStore(source.type);
  const realEntities = maybeMap(entities, (e) => getItem(store, e.id));
  const toCheckedState = useMultiCheckedState(realEntities, property);

  const handleChange = useCallback(
    (value: Maybe<boolean>) => {
      mutate(
        map(realEntities, (entity) => {
          const prev = getPropertyValue<Entity, PropertyType>(entity, property)[
            propType
          ];

          return toUpdate(entity, property, value, prev);
        })
      );
    },
    [realEntities, mutate]
  );

  return (
    <>
      <CommandItem
        value="clear unset value"
        onClick={() => handleChange(undefined)}
      >
        <Text subtle>Clear value</Text>
      </CommandItem>
      <CommandItem
        value={`checked true yes`}
        selected={!!toCheckedState(String(true) || "")}
        onClick={() => handleChange(true)}
        icon={<CheckIcon checked={true} />}
      >
        Yes
      </CommandItem>
      <CommandItem
        value={`unchecked false no`}
        selected={!!toCheckedState(String(true) || "")}
        onClick={() => handleChange(false)}
        icon={<CheckIcon checked={false} />}
      >
        No
      </CommandItem>
    </>
  );
};
