import { useSetRecoilState } from "recoil";

import { useLazyProperties } from "@state/properties";
import { resetFetchedAt } from "@state/fetch-results";
import { useSetting } from "@state/settings";
import { toTemplateViewId, ViewFetchResultsAtom } from "@state/views";
import {
  useActiveWorkspaceId,
  useLazyWorkspace,
  useUpdateWorkspace,
} from "@state/workspace";

import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation, toMutation } from "@utils/property-mutations";

import { Centered } from "@ui/container";
import { Divider } from "@ui/divider";
import { SpaceBetween, VStack } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { TimezoneSelect } from "@ui/select/timezone";
import { Heading, Text } from "@ui/text";

import { AddWorkButton } from "./add-work-dialog";
import SettingsSchema from "./settings-schemas";
import { TableLayout } from "./view-layouts";

interface Props {}

export function SettingsWorkspaceData({}: Props) {
  const wID = useActiveWorkspaceId();
  const entity = useLazyWorkspace();
  const mutate = useUpdateWorkspace();
  const timezone = useSetting<Maybe<string>>(wID, "timezone");

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Workspace Details</Heading>
        <Text subtle>Configure where how this workspace saves data.</Text>
      </VStack>

      <Divider direction="horizontal" />

      <VStack gap={20} fit="container">
        <Field label="Organisation Name">
          <TextInput
            value={entity?.name || ""}
            onChange={(name) =>
              entity &&
              name &&
              mutate([
                toMutation(entity, { field: "name", type: "text" }, name),
              ])
            }
            updateOn="blur"
            placeholder="Update team name..."
          />
        </Field>

        <Field label="Default Timezone">
          <TimezoneSelect
            value={timezone}
            onChange={(tz) =>
              mutate([
                asMutation({ field: "settings.timezone", type: "text" }, tz),
              ])
            }
            placeholder="Choose default timezone..."
          />
        </Field>
      </VStack>
    </Centered>
  );
}

export function SettingsWorkspaceProperties({}: Props) {
  const entity = useLazyWorkspace();

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Workspace Properties</Heading>
        <Text subtle>
          These properties are shared across your whole workspace. Changes here
          will effect all teams.
        </Text>
      </VStack>

      <Divider direction="horizontal" />

      {entity && <SettingsSchema parent={entity} />}
    </Centered>
  );
}

export function SettingsWorkspacePeople({}: Props) {
  const wID = useActiveWorkspaceId();
  const goTo = useGoTo();
  useLazyProperties({ type: "person", scope: wID });
  const viewId = toTemplateViewId("all-people", { parent: wID });
  const setFetchResults = useSetRecoilState(ViewFetchResultsAtom("all-people"));

  return (
    <Centered gap={20} stack="vertical">
      <SpaceBetween>
        <VStack gap={0}>
          <Heading bold>People</Heading>
          <Text subtle>Manage the people who are in this workspace.</Text>
        </VStack>
        <AddWorkButton
          defaults={{ source: { type: "person", scope: wID } }}
          onSaved={() => setFetchResults(resetFetchedAt())}
        >
          Add People
        </AddWorkButton>
      </SpaceBetween>

      <Divider direction="horizontal" />

      <VStack gap={20} fit="container">
        <TableLayout id={viewId} onOpen={goTo} />
      </VStack>
    </Centered>
  );
}

export function SettingsWorkspace({}: Props) {
  return (
    <VStack>
      <SettingsWorkspaceData />
      <SettingsWorkspaceProperties />
      <SettingsWorkspacePeople />
    </VStack>
  );
}
