import { flatMap, groupBy } from "lodash";
import { useCallback, useMemo } from "react";

import { Entity, PropertyRef, PropertyType, PropertyValue, Ref } from "@api";

import { fallback } from "@utils/fn";
import { ifDo } from "@utils/logic";
import { Maybe } from "@utils/maybe";
import { getPropertyValue } from "@utils/property-refs";

import { CheckedState } from "@ui/icon";

export const useMultiCheckedState = (things: Entity[], prop: PropertyRef) => {
  const lookup = useMemo(
    () =>
      groupBy(
        flatMap(
          things,
          (e) =>
            getPropertyValue<Entity, PropertyType>(e, prop)[prop.type] || []
        ),
        (v: PropertyValue[PropertyType]) => (v as Maybe<Ref>)?.id || String(v)
      ),
    [things, prop.field, prop.type]
  );

  return useCallback(
    (key: string) =>
      fallback<CheckedState>(
        () => ifDo(lookup[key]?.length === things?.length, () => true),
        () => ifDo(!!lookup[key]?.length, () => "indeterminate"),
        () => false
      ),
    [lookup]
  );
};
