import { NetworkError } from "@apollo/client/errors";
import { GraphQLError } from "graphql";
import { keys, map, reduce } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { Entity, EntityType, GraphError, Update } from "@api";
import {
  addErrorObserver,
  removeErrorObserver,
} from "@api/integrations/traction";

import {
  estimateMemoryCost,
  formatBytes,
  useClearAllStores,
  useClearStore,
} from "@state/debug";
import {
  useAllStores,
  useCombinedStore,
  useDiscardUpdate,
} from "@state/generic";
import { useJobQueue } from "@state/jobs";
import { SimpleStoreState } from "@state/store";

import { reverse } from "@utils/array";
import { usePointDate } from "@utils/date-fp";
import { useShortcut } from "@utils/event";
import { Fn } from "@utils/fn";
import { useShowMore, useStickyState } from "@utils/hooks";
import {
  isSelected,
  SelectionState,
  SetSelectionState,
  useKeyboardSelectable,
  useMouseSelectable,
  useSelectable,
  useSelectableState,
} from "@utils/selectable";
import { toArray } from "@utils/set";
import { secondsAgo, useTick } from "@utils/time";
import { SafeRecord } from "@utils/types";

import { Button } from "@ui/button";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { ClickableSection } from "@ui/clickable-section";
import { copyToClipboard } from "@ui/clipboard";
import { ConfirmationButton } from "@ui/confirmation-button";
import { Container } from "@ui/container";
import { DialogSplit } from "@ui/dialog-split";
import { Divider } from "@ui/divider";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  CopyIcon,
  Database,
  LaptopConnection,
  LayersAlt,
  TrashAlt,
} from "@ui/icon";
import { ListItem } from "@ui/list-item";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { showSuccess } from "@ui/notifications";
import { Tag } from "@ui/tag";
import { SectionLabel, Text, TextSmall, TextXLarge } from "@ui/text";

import { SaveErrorDialog, UpdateListItem } from "./saving-queue";

import styles from "./debug-dialog.module.css";

type Props = {
  onDismiss: Fn<void, void>;
};

export const DebugDialog = ({ onDismiss }: Props) => {
  const [selected, setSelected] = useStickyState<string>(
    "queue",
    "debug-dialog-tab"
  );

  const side = useMemo(
    () => (
      <SpaceBetween direction="vertical" fit="container">
        <VStack>
          <TextXLarge bold>Debugging</TextXLarge>
          <Menu>
            <MenuGroup>
              <MenuItem
                icon={LayersAlt}
                text="Sync Queue"
                selected={selected === "queue"}
                onClick={() => setSelected("queue")}
              />
              <MenuItem
                icon={LaptopConnection}
                text="Distributed Jobs"
                selected={selected === "jobs"}
                onClick={() => setSelected("jobs")}
              />
              <MenuItem
                icon={Database}
                text="Data Stores"
                selected={selected === "stores"}
                onClick={() => setSelected("stores")}
              />
            </MenuGroup>
          </Menu>
        </VStack>

        <Text subtle>
          Press cmd + shift + c at any time to open the debugging dialog.
        </Text>
      </SpaceBetween>
    ),
    [selected]
  );

  return (
    <DialogSplit
      side={side}
      actions={
        selected !== "queue" && (
          <>
            <Button onClick={() => onDismiss?.()}>Close</Button>
          </>
        )
      }
    >
      {selected === "queue" && <QueueDebugger onDismiss={onDismiss} />}

      {selected === "jobs" && <JobsDebugger onDismiss={onDismiss} />}

      {selected === "stores" && <StoresDebugger onDismiss={onDismiss} />}
    </DialogSplit>
  );
};

export const HardReloadObserver = () => {
  const clearAll = useClearAllStores(false);

  const handleHardReload = useCallback(() => {
    clearAll();
    setTimeout(() => window.location.reload(), 100);
  }, [clearAll]);

  useShortcut({ key: "KeyR", shift: true, command: true }, (e) => {
    handleHardReload();
    showSuccess("Cached data cleared.");
    e.preventDefault();
  });

  return <></>;
};

export const GlobalErrorObserver = () => {
  const clearAll = useClearAllStores(true);

  useEffect(() => {
    const observer = (e: GraphQLError | NetworkError) => {
      if (JSON.stringify(e)?.includes(GraphError.BadAuthorization)) {
        clearAll();
        setTimeout(() => (window.location.href = "/auth/login"), 500);
      }
    };

    addErrorObserver(observer);

    return () => removeErrorObserver(observer);
  }, []);

  return <></>;
};

export const DebugDialogObserver = () => {
  const [open, setOpen] = useState(false);

  useShortcut({ key: "KeyC", shift: true, command: true }, () =>
    setOpen((o) => !o)
  );

  if (!open) {
    return <></>;
  }

  return <DebugDialog onDismiss={() => setOpen(false)} />;
};

const QueueDebugger = ({ onDismiss }: Props) => {
  const [selected, setSelected] = useState<Update<Entity>[]>([]);
  const { updating, unsaved, history } = useCombinedStore();
  const [mode, setMode] = useState<"queue" | "history">("queue");
  const discard = useDiscardUpdate();

  const handleClearQueue = useCallback(() => {
    discard(unsaved);
  }, [unsaved]);

  const handleCopyAll = useCallback(() => {
    copyToClipboard(
      JSON.stringify(
        {
          unsaved,
          history,
          updating,
        },
        null,
        2
      )
    );
  }, [unsaved, history, updating]);

  const unsavedPaged = useShowMore(unsaved, 100);

  return (
    <SpaceBetween fit="container" direction="vertical">
      <VStack fit="container" gap={16}>
        {!!selected?.length && (
          <SaveErrorDialog
            updates={selected}
            onDismiss={() => setSelected([])}
          />
        )}

        <HStack fit="container" justify="flex-end">
          <ButtonGroup fit="container">
            <SplitButton
              size="small"
              selected={mode === "queue"}
              onClick={() => setMode("queue")}
            >
              Queue
            </SplitButton>
            <SplitButton
              size="small"
              selected={mode === "history"}
              onClick={() => setMode("history")}
            >
              History
            </SplitButton>
          </ButtonGroup>
        </HStack>

        {mode === "history" && (
          <VStack fit="container">
            {map(reverse(history), (u) => (
              <UpdateListItem
                key={`${u.id}/${u.queued}`}
                update={u}
                onClick={() => setSelected([u])}
              />
            ))}
            {!history?.length && <Text subtle>No saved changes.</Text>}
          </VStack>
        )}

        {mode === "queue" && (
          <>
            {!!updating?.length && (
              <ClickableSection title="Saving...">
                {map(updating, (u) => (
                  <UpdateListItem
                    key={`${u.id}/${u.queued}`}
                    update={u}
                    onClick={() => setSelected([u])}
                  />
                ))}
              </ClickableSection>
            )}
            {!!unsaved?.length && (
              <ClickableSection title="Queued">
                {map(unsavedPaged.visible, (u) => (
                  <UpdateListItem
                    key={`${u.id}/${u.queued}`}
                    update={u}
                    onClick={() => setSelected([u])}
                  />
                ))}
                {unsavedPaged.hasMore && (
                  <ShowMoreMenuItem
                    count={unsavedPaged.moreCount}
                    onClick={unsavedPaged.showMore}
                  />
                )}
              </ClickableSection>
            )}
            {!unsaved?.length && <Text subtle>Waiting for changes...</Text>}
          </>
        )}
      </VStack>
      <Container padding="bottom">
        <SpaceBetween>
          <HStack>
            <Button icon={CopyIcon} onClick={handleCopyAll}>
              Copy All
            </Button>
            {mode == "queue" && !!unsaved?.length && (
              <ConfirmationButton
                icon={TrashAlt}
                onConfirm={handleClearQueue}
                destructive
                dialogTitle="Are you sure you want to clear these unsaved changes?"
              >
                Clear Queue
              </ConfirmationButton>
            )}
          </HStack>

          <Button onClick={() => onDismiss?.()}>Close</Button>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};

const JobsDebugger = ({}: Props) => {
  const { jobs, check } = useJobQueue();

  // Force UI to update every second
  useTick("1 second");

  return (
    <VStack fit="container" gap={0}>
      {map(jobs, (job) => (
        <ListItem key={job.id}>
          <SpaceBetween>
            <HStack gap={4}>
              <SectionLabel>{job.id}</SectionLabel>
              <Text>{job.key}</Text>
            </HStack>

            <HStack gap={4}>
              <TextSmall subtle>
                {usePointDate(job.lockedAt, secondsAgo)} seconds
              </TextSmall>
              <Tag color={job.status === "running" ? "blue" : "gray"}>
                {job.status}
              </Tag>
            </HStack>
          </SpaceBetween>
        </ListItem>
      ))}

      {!jobs?.length && (
        <Text subtle>
          No running/queued jobs.{" "}
          <Button variant="link" onClick={() => check()}>
            Refresh.
          </Button>
        </Text>
      )}
    </VStack>
  );
};

const StoresDebugger = ({}: Props) => {
  const allStores = useAllStores() as SafeRecord<
    string,
    SimpleStoreState<Object>
  >;
  const allKeys = useMemo(() => keys(allStores), [allStores]);
  const [selection, setSelection] = useSelectableState();
  const clear = useClearStore();

  const estSize = useMemo(
    () =>
      formatBytes(
        reduce(
          toArray(selection.selected),
          (acc, id) => acc + estimateMemoryCost(allStores[id]),
          0
        )
      ),
    [selection.selected]
  );

  useKeyboardSelectable(selection, setSelection, true);
  useMouseSelectable(selection, setSelection, true);

  const handleClearSelected = useCallback(() => {
    map(toArray(selection.selected), (id) => clear(id as EntityType));
    window.location.reload();
  }, [selection.selected]);

  const handleDumpSelected = useCallback(() => {
    map(toArray(selection.selected), (id) =>
      copyToClipboard(JSON.stringify(allStores[id], null, 2))
    );
  }, [selection.selected]);

  const handleClearAll = useCallback(() => {
    map(allKeys, (id) => clear(id as EntityType));
    clear("jobs");
    clear("fetchResults");
    clear("props");
    clear("space");
    setTimeout(() => window.location.reload(), 500);
  }, [allKeys]);

  return (
    <VStack fit="container" gap={10}>
      <HStack fit="container" justify="flex-end">
        {!!selection.selected.size && (
          <HStack>
            <Text className={styles.blue}>
              {selection.selected.size} selected
            </Text>
            <Text>{estSize}</Text>
            <Button size="small" onClick={handleDumpSelected}>
              Dump ({selection.selected.size})
            </Button>
            <Button size="small" onClick={handleClearSelected}>
              Clear ({selection.selected.size})
            </Button>
          </HStack>
        )}
        {!selection.selected.size && (
          <Button size="small" onClick={handleClearAll}>
            Clear All
          </Button>
        )}
      </HStack>

      <Divider />

      <VStack gap={0}>
        {map(allKeys, (storeKey) => (
          <SelectableListItem
            key={storeKey}
            id={storeKey}
            selection={selection}
            setSelection={setSelection}
          >
            <SpaceBetween className={styles.noMouse}>
              <SectionLabel>{storeKey}</SectionLabel>
              <TextSmall subtle>
                {keys(allStores[storeKey]?.lookup)?.length} items
              </TextSmall>
            </SpaceBetween>
          </SelectableListItem>
        ))}
      </VStack>
    </VStack>
  );
};

interface ListItemProps {
  id: string;
  selection: SelectionState;
  setSelection: SetSelectionState;
  children: ReactNode;
  className?: string;
}

const SelectableListItem = ({
  children,
  selection,
  className,
  id,
}: ListItemProps) => {
  const selectableProps = useSelectable(id);
  const selected = useMemo(
    () => !!selection && isSelected(selection, id),
    [selection, id]
  );
  return (
    <ListItem
      selectable={true}
      {...selectableProps}
      selected={selected}
      className={className}
    >
      {children}
    </ListItem>
  );
};
