import { find, isString } from "lodash";
import { useMemo, useState } from "react";

import { PersonRole } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useAddToFetchResults } from "@state/fetch-results";
import { useMe } from "@state/persons";
import { useLazyAllTeams } from "@state/teams";
import { useCurrentWorkspace } from "@state/workspace";

import { useStickyState } from "@utils/hooks";
import { equalsAny } from "@utils/logic";
import { toRef } from "@utils/property-refs";

import { Button } from "@ui/button";
import { TeamCreateDialog } from "@ui/engine/team";
import { SpaceBetween, VStack } from "@ui/flex";
import { PlusAlt } from "@ui/icon";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { TabBar } from "@ui/tab-bar";
import { TeamStructureFlow } from "@ui/team-structure-flow";
import { Heading, Text } from "@ui/text";

import AppPage from "./app-page";

import styles from "./teams-page.module.css";

interface Props {}

export function TeamsPage({}: Props) {
  const me = useMe();
  const workspace = useCurrentWorkspace();
  const [page] = useRegisterPage();
  const allTeams = useLazyAllTeams();
  const onTeamAdded = useAddToFetchResults("all-teams"); // Matches internal cache key inside useAllLazyTeams
  const [addingTeam, setAddingTeam] = useState<boolean | string>(false);
  const addingDefaults = useMemo(
    () =>
      !addingTeam
        ? undefined
        : isString(addingTeam)
        ? {
            parent: toRef(addingTeam),
            owner: toRef(me),
            color: find(allTeams, (t) => t.id === addingTeam)?.color,
          }
        : { owner: toRef(me) },
    [addingTeam, allTeams]
  );
  const editable = useMemo(
    () => equalsAny(me.role?.id, [PersonRole.Admin, PersonRole.Owner]),
    [me.role]
  );
  const [filter, setFilter] = useStickyState<"all" | "me">(
    "me",
    "spaces-filter"
  );

  usePageUndoRedo(page.id);

  return (
    <AppPage page={page}>
      <StackContainer>
        {!!addingDefaults && (
          <TeamCreateDialog
            defaults={addingDefaults}
            onCancel={() => setAddingTeam(false)}
            onSaved={(t) => {
              onTeamAdded([t]);
              setAddingTeam(false);
            }}
          />
        )}

        <Sheet size="full" transparency="low" interactable={false}>
          <VStack fit="container" height="container">
            <div className={styles.header}>
              <SpaceBetween>
                <VStack gap={0} fit="content">
                  <Heading bold>All Teams</Heading>
                  <Text subtle>At {workspace?.name || "your company."}</Text>
                </VStack>

                <TabBar
                  active={filter}
                  onActiveChanged={(v) => setFilter(v as "all" | "me")}
                  className={styles.tabBar}
                  itemClassName={styles.tabBarItem}
                  options={[
                    { id: "all", title: "All Teams" },
                    { id: "me", title: "My Teams" },
                  ]}
                />

                {editable && (
                  <Button onClick={() => setAddingTeam(true)} icon={PlusAlt}>
                    New team
                  </Button>
                )}
              </SpaceBetween>
            </div>

            <TeamStructureFlow className={styles.full} mode={filter} />
          </VStack>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
}
