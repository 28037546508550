import { isEmpty, map } from "lodash";

import { Form } from "@api";

import { FormData, useFormFields } from "@state/form";
import { useLazyEntity } from "@state/generic";

import { justOne } from "@utils/array";
import { formatAccurate } from "@utils/date";
import { usePointDate } from "@utils/date-fp";
import { useStickyState } from "@utils/hooks";
import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import {
  asPropertyValueRef,
  getPropertyValue,
  toFieldName,
} from "@utils/property-refs";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { CopyLinkButton } from "@ui/copy-link-button";
import { Divider } from "@ui/divider";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  BoltAlt,
  ClipboardNotes,
  EditAlt,
  Icon,
  iconFromString,
} from "@ui/icon";
import { Field } from "@ui/input";
import { LocationBreadcrumb } from "@ui/location-button";
import { PackageTag } from "@ui/package-label";
import { PropertyValue } from "@ui/property-value";
import { RelationButton, RelationLabel } from "@ui/relation-label";
import { Sheet } from "@ui/sheet-layout";
import { Heading, Text } from "@ui/text";

import { FormSubmit } from "./submit";

import styles from "./styles.module.css";

interface Props {
  id: string;
}

export const TemplateFormPane = ({ id }: Props) => {
  const form = useLazyEntity<"form">(id);
  // @ts-ignore --- FormData <> JSON
  const [formData, setFormData] = useStickyState<FormData>(
    {},
    `form-draft-${id}`
  );

  if (!form) {
    return <></>;
  }

  return (
    <Sheet size="primary">
      <FormHeaderBar form={form} />

      <Container stack="vertical" gap={30}>
        <SpaceBetween align="flex-end">
          <VStack>
            <Icon
              size="large"
              icon={when(form.icon, iconFromString) || ClipboardNotes}
            />
            <Heading bold>{form.name}</Heading>
            <HStack gap={4}>
              <Text subtle>Form built by</Text>
              <RelationLabel subtle relation={form.owner || form.createdBy} />
            </HStack>
          </VStack>

          {!isEmpty(formData) && (
            <Button onClick={() => setFormData({})}>Restart</Button>
          )}
        </SpaceBetween>

        <Divider />

        <FormSubmit form={form} data={formData} onChanged={setFormData} />
      </Container>
    </Sheet>
  );
};

export const SubmittedFormPane = ({ id }: Props) => {
  const form = useLazyEntity<"form">(id);
  const fields = useFormFields(form);

  if (!form) {
    return <></>;
  }

  return (
    <Sheet size="primary">
      <Container stack="vertical" gap={30}>
        <EntityHeaderBar entity={form} />
        <SpaceBetween align="flex-end">
          <VStack>
            <HStack>
              <Icon
                size="large"
                icon={when(form.icon, iconFromString) || ClipboardNotes}
              />
              <Heading bold>{form.name}</Heading>
            </HStack>
            <HStack gap={4}>
              <Text subtle>Form submitted by</Text>
              <RelationLabel subtle relation={form.createdBy} />
              <Text subtle>
                at {usePointDate(form.createdAt, (d) => formatAccurate(d))}
              </Text>
            </HStack>
          </VStack>
        </SpaceBetween>

        <Divider />

        {map(fields, ([field]) => (
          <Field
            key={field.field}
            label={field.label || toFieldName(field)}
            help={field.description}
          >
            <PropertyValue
              className={styles.control}
              valueRef={asPropertyValueRef(
                field,
                getPropertyValue(form, {
                  field: field.field,
                  type: field.type,
                })
              )}
              source={form.source}
              fit="container"
              editable={false}
            />
          </Field>
        ))}

        <HStack gap={4}>
          <Icon icon={BoltAlt} />
          <Text>On submission this form</Text>

          {when(justOne(form.refs.fromWorkflow), (ref) => (
            <>
              <Text> ran </Text>
              <RelationButton relation={ref} />
            </>
          ))}

          {when(justOne(form.refs.created), (ref) => (
            <>
              <Text> created </Text>
              <RelationButton relation={ref} />
            </>
          ))}
        </HStack>
      </Container>
    </Sheet>
  );
};

const FormHeaderBar = ({ form }: { form: Form }) => {
  const pushTo = usePushTo();
  return (
    <Container padding="both">
      <SpaceBetween>
        <Container
          inset="left"
          padding="none"
          fit="content"
          stack="horizontal"
          gap={0}
        >
          <LocationBreadcrumb
            editable={false}
            location={form.location}
            variant="full"
          />
        </Container>

        <HStack gap={3}>
          <HStack gap={2}>
            <PackageTag type={form.source.type} scope={form.source.scope} />
            <CopyLinkButton entity={form} size="small" />
            <Button
              size="small"
              icon={EditAlt}
              subtle
              onClick={() => pushTo("/builder")}
            >
              Builder
            </Button>
          </HStack>
        </HStack>
      </SpaceBetween>
    </Container>
  );
};
