import { useMemo } from "react";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity, useMarkAsSeen } from "@state/generic";
import { isHost } from "@state/meetings";
import { useMe } from "@state/persons";

import { when } from "@utils/maybe";
import { getRelationValue } from "@utils/property-refs";
import { useSyncPathnameID } from "@utils/url";

import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { MeetingActionsPane } from "@ui/engine/action/pane";
import {
  MeetingHostPane,
  MeetingLinksPane,
  MeetingPane,
} from "@ui/engine/meeting";
import { MeetingParticipantPane } from "@ui/engine/meeting/host-pane";
import { ScheduleInstancesPane } from "@ui/engine/schedule";
import { CheckIcon, ClockHistory, RelationIcon, Search } from "@ui/icon";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { StackContainer } from "@ui/sheet-layout";
import { TemplatePaneManager } from "@ui/template-pane-manager";

import AppPage from "./app-page";

interface Props {
  meetingId: string;
}

const MeetingPage = ({ meetingId }: Props) => {
  const me = useMe();
  const meeting = useLazyEntity<"meeting">(meetingId);
  const hosting = useMemo(
    () => !!meeting && isHost(meeting, me),
    [meeting?.owner?.id, me?.id]
  );

  const [page] = useRegisterPage(meetingId, meeting);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(meetingId, meeting?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(meetingId, page.id);

  if (!meeting) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!meeting} title={meeting?.name}>
      <StackContainer>
        <SmartBreadcrumbSheet />

        <MeetingPane id={meetingId} item={meeting} />

        {!meeting.template && (
          <PaneManager size="secondary">
            {hosting && (
              <PaneItem id="host" title="Host Tools" icon={Search}>
                <MeetingHostPane meeting={meeting} />
              </PaneItem>
            )}

            {!hosting && <MeetingParticipantPane meeting={meeting} />}

            <PaneItem id="linked" title="Linked work" icon={RelationIcon}>
              <MeetingLinksPane meeting={meeting} />
            </PaneItem>

            <PaneItem
              id="actions"
              title="Actions"
              icon={<CheckIcon checked={true} color="red" />}
            >
              <MeetingActionsPane meeting={meeting} />
            </PaneItem>

            {when(getRelationValue(meeting, "refs.repeat"), (ref) => (
              <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
                <ScheduleInstancesPane
                  title="Recurring Meeting"
                  schedule={ref}
                  instanceId={meeting.id}
                />
              </PaneItem>
            ))}
          </PaneManager>
        )}

        {!!meeting.template && (
          <TemplatePaneManager id={meetingId} entity={meeting} />
        )}
      </StackContainer>
    </AppPage>
  );
};

export default MeetingPage;
