import { find, map } from "lodash";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useSetRecoilState } from "recoil";

import { Entity, Job, JobStatus, Update, updateJobStatus } from "@api";

import {
  finishRunning,
  isRunning,
  isTimedOut,
  JobStoreAtom,
  RUNNERS,
  setJob,
  useQueueNextJob,
} from "@state/jobs";

import { ensureMany, OneOrMany } from "@utils/array";
import { useISODate } from "@utils/date-fp";
import { addInfo, debug, log } from "@utils/debug";
import { usePreventClose, useWindowEvent } from "@utils/event";
import { Fn } from "@utils/fn";
import { useOnce } from "@utils/hooks";
import { required, when } from "@utils/maybe";
import { secondsAgo, useTick } from "@utils/time";

import { useMutate, WithMutateContext } from "@ui/mutate";

const replaceCreatedBy = (
  updates: OneOrMany<Update<Entity>>,
  id: string
): Update<Entity>[] =>
  map(ensureMany(updates), (u) =>
    u.method === "create"
      ? {
          ...u,
          changes: [
            ...u.changes,
            {
              field: "createdBy",
              type: "relation",
              value: { relation: { id } },
            },
          ],
        }
      : u
  );

export const JobsQueue = () => {
  const _mutate = useMutate();
  const running = useQueueNextJob();

  const mutate = useCallback(
    (updates: OneOrMany<Update<Entity>>) => {
      if (running?.createdBy?.id) {
        _mutate(replaceCreatedBy(updates, running.createdBy.id));
      } else {
        _mutate(updates);
      }
    },
    [running?.createdBy?.id, _mutate]
  );

  if (!running) {
    return <></>;
  }

  return (
    <div style={{ display: "none" }}>
      <WithMutateContext mutate={mutate}>
        <JobRunner job={running} />
      </WithMutateContext>
    </div>
  );
};

interface RunnerProps {
  job: Job;
  onCompleted?: Fn<Job, void>;
}

const JobRunner = memo(
  ({ job, onCompleted }: RunnerProps) => {
    const mutate = useMutate();
    const Runner = useMemo(
      () =>
        required(
          find(RUNNERS, (r) => r.accepts(job))?.runner,
          () => "Runner not found for job."
        ),
      []
    );
    const setStore = useSetRecoilState(JobStoreAtom);
    const [once] = useOnce(
      job ? `job-runner-${job.id}-${job.lockedAt}-${job.lockKey}` : "never"
    );

    const handleCompleted = useCallback(async () => {
      if (!job) {
        return;
      }

      try {
        const updated = await updateJobStatus(job.id, JobStatus.Completed);

        setStore(finishRunning(updated || job));

        onCompleted?.(updated || job);
      } catch (err) {
        addInfo({ state: "Trying to complete running job.", job });
        log(err);

        setStore(finishRunning(job));
      }
    }, [setStore, onCompleted, job?.id]);

    const handleFailed = useCallback(async () => {
      if (!job) {
        return;
      }

      try {
        const updated = await updateJobStatus(job.id, JobStatus.Failed);
        setStore(finishRunning(updated || job));
      } catch (err) {
        addInfo({ state: "Trying to complete running job.", job });
        log(err);

        setStore(finishRunning(job));
      }
    }, [setStore, job?.id]);

    usePreventClose(
      useMemo(() => job && isRunning(job) && !isTimedOut(job), [job?.status]),
      () =>
        "Background jobs are being run. Leaving now could cause faulty data. Wait a few seconds and try again. Or reload anyway?"
    );

    // Auto failing jobs that were locked for more than two minutes
    useEffect(() => {
      if (useISODate(job?.lockedAt, (d) => secondsAgo(d) > 120)) {
        debug("Job locked for more than two minutes.", job);
        handleFailed();
      }
    }, [useTick("10 seconds")]);

    return (
      <Runner
        job={job}
        once={once}
        mutate={mutate}
        onCompleted={handleCompleted}
        onFailed={handleFailed}
      />
    );
  },
  (prev, next) => prev.job?.id === next.job.id
);
